require("imports-loader?this=>window!./vendor/modernizr/modernizr");

import $ from "jquery";

$.migrateMute = true;
$.migrateTrace = false;
import "jquery-migrate";

$.extend({
    getQueryParameters: function (str) {
        return (str || document.location.search).replace(/(^\?)/, '').split("&").map(function (n) {
            return n = n.split("="), this[n[0]] = n[1], this
        }.bind({}))[0];
    }
});


global.jQuery = $;
global.$ = $;

require("./vendor/bxslider/jquery.bxslider.min.js");
require("./vendor/bxslider/plugins/jquery.fitvids.js");

$(document).ready(function () {
    const $similar_list = $('.similar__list'), $recent_list = $('.recent__list');

    $([$similar_list, $recent_list]).each(function (index, $container) {
        if ($container.length === 1) {
            const items_count = $container.find("li").length;
            $container.bxSlider({
                minSlides: 1,
                maxSlides: 4,
                slideWidth: 165,
                slideMargin: 5,
                pager: false,
                controls: items_count > 3,
                infiniteLoop: items_count > 5,
                onSliderLoad: function () {
                    $container.css('visibility', 'visible')
                }
            })
        } else if ($container.length > 1) {
            $($container).each(function (index, elem) {
                const $container = $(elem),
                    items_count = $container.find("li").length;
                $container.bxSlider({
                    minSlides: 1,
                    maxSlides: 4,
                    slideWidth: 165,
                    slideMargin: 5,
                    pager: false,
                    controls: items_count > 3,
                    infiniteLoop: items_count > 5,
                    onSliderLoad: function () {
                        $container.css('visibility', 'visible')
                    }
                })
            })
        }
    });
});